import {acquireAccessToken, graphConfig, msalInstance, MsEndpoint} from "./msal";
import memoizer from "lru-memoizer";
import {GetCalendarPromiseType, ItemWrapper} from "./collectCalendars";

type CalendarEventsListResponse = {
    value: CalendarEvent[]
}

type CalendarEvent = {
    id: string,
    start: {dateTime: string},
    end: {dateTime: string},
    subject: string,
    bodyPreview: string,
    webLink: string,
    attendees: any[]
};

export function isSignedIn() {
    const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
    const accounts = msalInstance.getAllAccounts();

    return activeAccount || accounts.length > 0
}

export async function callMsGraph(endpoint: MsEndpoint, id?: string, parameters?: string) {
    const url = graphConfig[endpoint](id);
    const accessToken = await acquireAccessToken(msalInstance).then((result) => result && result.accessToken);

    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    const urlWithParams = parameters !== undefined ? url + parameters : url;
    return fetch(urlWithParams, options)
        .then(response => response.json());
}

export const getCalendarPromise: GetCalendarPromiseType = memoizer.sync({
    // @ts-ignore
    load: (calendarId: string, startTime: Date, endTime: Date) => {
        return collect(
            calendarId, startTime, endTime
        ).then((response: CalendarEventsListResponse) => {
            return responseToItem(calendarId, response)
        }).catch((response: CalendarEventsListResponse) => {
            return failToItem(calendarId, response);
        });
    },

    //defines how to create a cache key from the params.
    // @ts-ignore
    hash: (calendarId, startTime, endTime) => {
        return JSON.stringify([calendarId, startTime, endTime]);
    },

    max: 1000,
    // allow refresh every hour
    maxAge: 1000 * 60 * 60
});

async function collect(calendarId: string, startTime: Date, endTime: Date): Promise<CalendarEventsListResponse> {
    const startIso = startTime.toISOString();
    const endIso = endTime.toISOString();
    return await callMsGraph(
        "calendarEvents",
        calendarId,
        "?$filter=start/dateTime ge '" + startIso + "' and end/dateTime le '" + endIso + "'"
    );
}


function responseToItem(calendarId: string, response: CalendarEventsListResponse): ItemWrapper {
    const events = response.value;

    const eventList = events.map(event => {
        let start = event.start.dateTime;
        let end = event.end.dateTime;

        return {
            id: calendarId + ":" + event.id,
            group: calendarId,
            title: event.subject,
            description: event.bodyPreview,
            link: event.webLink,
            start: (new Date(start)).getTime(),
            end: (new Date(end)).getTime(),
            source: "outlook",
            canMove: false,
            canChangeGroup: false,
            canResize: false
        };
    });

    const attendees = events.map(event => event.attendees).flat()
        .filter(att => att && "email" in att)
        .map(a => a.email);

    return {
        id: calendarId,
        success: true,
        events: eventList,
        calendarOptions: attendees,
        source: "outlook"
    }
}

function failToItem(calendarId: string, _response: CalendarEventsListResponse): ItemWrapper {
    // TODO: I should probably parse the reason for the fail
    console.log("nope");
    return {
        id: calendarId,
        success: false,
        events: [],
        calendarOptions: [],
        source: "outlook"
    };
}
