import {Typography} from "@mui/material";
import {Trans, useTranslation} from "react-i18next";
import DOMPurify from "dompurify";

export default function HelpText() {
    const { t } = useTranslation();

    return <>
        <Typography paragraph>
            {t("settings.helpText.view")}
        </Typography>
        <Typography paragraph>
            <Trans i18nKey="settings.helpText.filter">
                The appointments can be filtered with regular expressions, e.g., the filter "word" will only show
                the events which contain "word" (case insensitive). The filter "vacation|meeting" will only show
                events containing one of "vacation" or "meeting" in their title. "^(?!.*?zoom).*" will show only
                events which do not contain "zoom" in their More details at
                {/* nosemgrep : the text is sanitized (and also its source is the translation file and not user input) */}
                <a href={DOMPurify.sanitize(t("settings.helpText.filterLink"))}>MDN</a>.
            </Trans>
        </Typography>
        <Typography paragraph>
            {t("settings.helpText.add")}
        </Typography>
        <Typography paragraph>
            {t("settings.helpText.group")}
        </Typography>
        <Typography paragraph>
            {t("settings.helpText.sources")}
        </Typography>
    </>
}
