import {MouseEvent} from "react";
import {Button, IconButton, Stack} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {useTranslation} from "react-i18next";

export interface CalendarGroups {
    [key: string]: string[]
}

type Props = {
    calendarGroups: CalendarGroups,
    activateCalendars: (selection: string[]) => void,
    deleteCalendarGroup: (calendarGroupName: string) => void,
}

export default function CalendarGroupSelect(props: Props) {
    const { t } = useTranslation();

    const calendarGroupOptions = Object.entries(props.calendarGroups).map(([name, selection]) => {
        return(
            <Stack
                key={name}
                direction={"row"}
            >
                <IconButton
                    color="primary"
                    data-testid={"groupDeleteButton:"+name}
                    aria-label="delete"
                    component="span"
                    onClick={(_event: MouseEvent) => props.deleteCalendarGroup(name)}
                >
                    <DeleteIcon />
                </IconButton>
                <Button
                    onClick={(_event: MouseEvent) => props.activateCalendars(selection)}
                    sx={{textTransform: "none"}}
                    variant={"outlined"}
                >
                    {name}
                </Button>
            </Stack>
        )
    });

    return(
        <Stack spacing={2}>
            {calendarGroupOptions.length === 0 && t("settings.calendarGroupSelect.noGroups")}
            {calendarGroupOptions}
        </Stack>
    );
}
