import React, {useState} from 'react'
import reactCSS from 'reactcss'
import {TwitterPicker} from 'react-color'

type Props = {
    color: string
    setColor: (color: string) => void
}

export default function ColorPopper(props: Props) {
    const[open, setOpen] = useState(false);
    // Workaround: we should not copy the prop into state, but for some reason,
    //  the color is not updated if we use props.color in the style
    // nosemgrep
    const[color, setColor] = useState(props.color);

    function handleClick() {
        setOpen(!open);
    }

    function handleClose() {
        setOpen(false);
    }

    function handleChange(color: any) {
        setColor(color.hex);
        props.setColor(color.hex);
    }

    const styles = reactCSS({
        'default': {
            color: {
                width: '36px',
                height: '14px',
                borderRadius: '2px',
                background: color,
            },
            swatch: {
                padding: '5px',
                background: '#fff',
                borderRadius: '1px',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                display: 'inline-block',
                cursor: 'pointer',
            },
            popover: {
                position: 'absolute',
                zIndex: '2',
            },
            cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
            },
        },
    });

    return (
        <div>
            <div style={ styles.swatch } onClick={ handleClick }>
                <div style={ styles.color } />
            </div>
            {/* @ts-ignore */}
            { open && <div style={ styles.popover }>
                {/* @ts-ignore */}
                <div style={ styles.cover } onClick={ handleClose }/>
                <TwitterPicker
                    width={"100%"}
                    onChangeComplete={handleChange}
                />
            </div>
            }

        </div>
    )
}

