import React, {useEffect, useMemo, useReducer, useState, Reducer} from "react";
import {
    Button,
    Divider,
    Drawer,
    IconButton
} from "@mui/material";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuIcon from "@mui/icons-material/Menu";
import SimpleTimeline, {Group, Item} from "./SimpleTimeline";
import {collectCalendars, ItemWrapper} from "../collectCalendars";
import moment from "moment";
import {DrawerHeader, drawerWidth, Main} from "../drawerStyle";
import Settings from "./settings/Settings";
import {useTranslation} from "react-i18next";
import {exportJson, getObjFromStorage, importJson, saveObjToStorage} from "./settings/ImportExport";
import {Jump} from "./settings/Navigation";
import {defaultColors} from "./settings/ColorSelect";
import {loadJsonFromDrive, saveJsonToDrive} from "../uploadToDrive";

// the calendar component does not like an empty group array, so we will pass it this dummy group instead
const emptyGroup: Group = {
    id: "none",
    title: "",
    rightTitle: "",
}

const defaultCalendarIDs = '["primary"]';
const defaultCalendarActive = '["primary"]';

export type CalendarId = string;

type Props = {}

type CalendarState = {
    ids: CalendarId[],
    active: Set<CalendarId>,
    groups: {[key: CalendarId]: CalendarId[]},
    invalid: Set<CalendarId>,
    options: Set<CalendarId>
}

type CalendarAction = {
    type: "add"|"rm"|"activate"|"deactivate",
    id: CalendarId
} | {
    type: "addGroup",
    name: string,
    selection: CalendarId[]
} | {
    type: "rmGroup",
    name: string,
} | {
    type: "invalidate"|"addOptions"
    ids: Set<CalendarId>
} | {
    type: "loadStorage"
} | {
    type: "discardCache"
};

type VisibleTimeState = {
    start: number,
    end: number
};

type VisibleTimeAction = {
    type: "jump",
    jump: Jump
} | {
    type: "set",
    start: number,
    end: number
};

export default function CalendarView(_props: Props) {
    const initCalendarIDs = getObjFromStorage('calendarIDs', defaultCalendarIDs);
    const initCalendarActive = new Set<CalendarId>(getObjFromStorage('calendarActive', defaultCalendarActive));
    const initCalendarGroups = getObjFromStorage('calendarGroups', "{}");
    const initialColors = {...defaultColors, ...getObjFromStorage('sourceColors', '{}')};

    const defaultTimeStart = moment()
        .startOf("day")
        .toDate();
    const defaultTimeEnd = moment()
        .startOf("day")
        .add(1, "week")
        .toDate();

    const [groups, setGroups] = useState([emptyGroup]);
    const [items, setItems] = useState<Item[]>([]);
    const [filter, setFilter] = useState("");
    const [startTime, setStartTime] = useState(defaultTimeStart);
    const [endTime, setEndTime] = useState(defaultTimeEnd);
    const [drawerOpen, setDrawerOpen] = useState(true);
    const [colors, setColors] = useState(initialColors);

    const [jsonLoaded, setJsonLoaded] = useState(false);

    const [visibleTime, visibleTimeDispatch] = useReducer<Reducer<VisibleTimeState, VisibleTimeAction>>(visibleTimeReducer, {
        start: defaultTimeStart.valueOf(),
        end:defaultTimeEnd.valueOf()
    });

    const [calendarState, calendarDispatch] = useReducer<Reducer<CalendarState, CalendarAction>>(calendarReducer, {
        ids: initCalendarIDs,
        active: initCalendarActive,
        groups: initCalendarGroups,
        invalid: new Set(),
        options: new Set()
    });

    const { t } = useTranslation();

    // save these everytime they change
    useEffect(() => {
        saveObjToStorage('calendarIDs', calendarState.ids);
    }, [calendarState.ids])

    useEffect(() => {
        saveObjToStorage('calendarActive', [...calendarState.active]);
    }, [calendarState.active])

    useEffect(() => {
        saveObjToStorage('calendarGroups', calendarState.groups);
    }, [calendarState.groups])

    useEffect(() => {
        saveObjToStorage('sourceColors', colors);
    }, [colors])

    useEffect(() => {
        const responsePromise = loadJsonFromDrive("calendarOverview.json");
        responsePromise.then(response => {
            importJson(response.body);
            setJsonLoaded(true);
        });
    }, [])

    useEffect(() => {
        // do not try to save something before we have loaded the config
        jsonLoaded && saveJsonToDrive("calendarOverview.json", exportJson())
        // we explicitly do not listen for `jsonLoaded`
        // eslint-disable-next-line
    }, [calendarState.ids, calendarState.active, calendarState.groups, colors])

    function calendarReducer(state: CalendarState, action: CalendarAction): CalendarState {
        switch(action.type) {
            case "add":
                const newCalendarIDsAdd = [...new Set([...state.ids, action.id])];
                return {...state, ids: newCalendarIDsAdd};
            case "rm":
                const newCalendarIDsRm = state.ids.filter((i: CalendarId) => i !== action.id);
                return {...state, ids: newCalendarIDsRm}
            case "activate":
                const newCalendarActiveAdd = new Set([...state.active, action.id])
                return {...state, active: newCalendarActiveAdd};
            case "deactivate":
                let newCalendarActiveRm = new Set([...state.active]);
                newCalendarActiveRm.delete(action.id);
                return {...state, active: newCalendarActiveRm};
            case "addGroup":
                const newCalendarGroupsAdd = {...state.groups, ...{[action.name]: action.selection}};
                return {...state, groups: newCalendarGroupsAdd};
            case "rmGroup":
                const {[action.name]: _, ...newCalendarGroupsRm} = state.groups;
                return {...state, groups: newCalendarGroupsRm};
            case "invalidate":
                const newCalendarInvalid = new Set([...state.invalid, ...action.ids]);
                return {...state, invalid: newCalendarInvalid};
            case "addOptions":
                const newCalendarOptions = new Set([...state.options, ...action.ids]);
                return {...state, options: newCalendarOptions};
            case "loadStorage":
                const calendarIDs = getObjFromStorage('calendarIDs', defaultCalendarIDs);
                const calendarActive = new Set<CalendarId>(getObjFromStorage('calendarActive', defaultCalendarActive));
                const calendarGroups = getObjFromStorage('calendarGroups', "{}");
                return {...state, ids: calendarIDs, active: calendarActive, groups: calendarGroups};
            case "discardCache":
                return {...state, active: new Set([...state.active, ...state.invalid]), invalid: new Set()};
        }
    }

    function handleAddCalendar(id: CalendarId) {
        calendarDispatch({type: "add", id: id});
        handleSelectCalendar(id, true);
    }

    function handleRemoveCalendar(id: CalendarId) {
        calendarDispatch({type: "rm", id: id});
        handleSelectCalendar(id, false);
    }

    function handleSelectCalendar(id: CalendarId, isActive: boolean) {
        calendarDispatch({type: isActive ? "activate" : "deactivate", id: id})
    }

    function handleSaveCalendarGroup(name: string, selection: string[]) {
        calendarDispatch({type: "addGroup", name: name, selection: selection})
    }

    function handleDeleteCalendarGroup(name: string) {
        calendarDispatch({type: "rmGroup", name: name})
    }

    function handleActivateCalendars(ids: CalendarId[]) {
        const idSet = new Set(ids);

        // if necessary add calendars, which are not in the list yet
        for(let id of idSet) {
            handleAddCalendar(id);
        }

        // then activate the calendars in the group
        for(let id of calendarState.ids) {
            handleSelectCalendar(id, idSet.has(id));
        }
    }

    function loadStorage() {
        calendarDispatch({type: "loadStorage"});
    }

    function msLoginChanged(_loggedIn: boolean) {
        calendarDispatch({type: "discardCache"});
    }

    function toggleDrawerOpen() {
        setDrawerOpen(!drawerOpen);
    }

    function timeFrameChanged(start: number, end: number) {
        setStartTime(new Date(start));
        setEndTime(new Date(end));
    }

    function visibleTimeChanged(start: number, end: number) {
        visibleTimeDispatch({type: "set", start: start, end: end});
    }

    function jumpTo(jump: Jump) {
        visibleTimeDispatch({type: "jump", jump: jump});
    }
    function visibleTimeReducer(state: VisibleTimeState, action: VisibleTimeAction): VisibleTimeState {
        let start;
        let end;
        switch (action.type) {
            case "set":
                return {start: action.start, end: action.end}
            case "jump":
                switch (action.jump) {
                    case "dayNext":
                        start = moment(state.start).add(1, "day").valueOf();
                        end = moment(state.end).add(1, "day").valueOf();
                        break;
                    case "dayNow":
                        start = moment().startOf("day").valueOf();
                        end = moment(start).add(1, "day").valueOf();
                        break;
                    case "dayPrev":
                        start = moment(state.start).subtract(1, "day").valueOf();
                        end = moment(state.end).subtract(1, "day").valueOf();
                        break;
                    case "dayZoom":
                        start = state.start;
                        end = moment(start).add(1, "day").valueOf();
                        break;
                    case "weekNext":
                        start = moment(state.start).add(1, "week").valueOf();
                        end = moment(state.end).add(1, "week").valueOf();
                        break;
                    case "weekNow":
                        start = moment().startOf("week").valueOf();
                        end = moment(start).add(1, "week").valueOf();
                        break;
                    case "weekPrev":
                        start = moment(state.start).subtract(1, "week").valueOf();
                        end = moment(state.end).subtract(1, "week").valueOf();
                        break;
                    case "weekZoom":
                        start = state.start;
                        end = moment(start).add(1, "week").valueOf();
                        break;
                    case "fortnightNext":
                        start = moment(state.start).add(2, "week").valueOf();
                        end = moment(state.end).add(2, "week").valueOf();
                        break;
                    case "fortnightNow":
                        start = moment().startOf("week").valueOf();
                        end = moment(start).add(2, "week").valueOf();
                        break;
                    case "fortnightPrev":
                        start = moment(state.start).subtract(2, "week").valueOf();
                        end = moment(state.end).subtract(2, "week").valueOf();
                        break;
                    case "fortnightZoom":
                        start = state.start;
                        end = moment(start).add(2, "week").valueOf();
                        break;
                    case "monthNext":
                        start = moment(state.start).add(1, "month").valueOf();
                        end = moment(state.end).add(1, "month").valueOf();
                        break;
                    case "monthNow":
                        start = moment().startOf("month").valueOf();
                        end = moment(start).add(1, "month").valueOf();
                        break;
                    case "monthPrev":
                        start = moment(state.start).subtract(1, "month").valueOf();
                        end = moment(state.end).subtract(1, "month").valueOf();
                        break;
                    case "monthZoom":
                        start = state.start;
                        end = moment(start).add(1, "month").valueOf();
                        break;
                    case "quarterNext":
                        start = moment(state.start).add(1, "quarter").valueOf();
                        end = moment(state.end).add(1, "quarter").valueOf();
                        break;
                    case "quarterNow":
                        start = moment().startOf("quarter").valueOf();
                        end = moment(start).add(1, "quarter").valueOf();
                        break;
                    case "quarterPrev":
                        start = moment(state.start).subtract(1, "quarter").valueOf();
                        end = moment(state.end).subtract(1, "quarter").valueOf();
                        break;
                    case "quarterZoom":
                        start = state.start;
                        end = moment(start).add(1, "quarter").valueOf();
                        break;
                    case "zoomIn":
                        const durationIn = state.end - state.start;
                        const zoomIn = durationIn * 0.1;
                        start = state.start + zoomIn;
                        end = state.end - zoomIn;
                        break;
                    case "zoomOut":
                        const durationOut = state.end - state.start;
                        const zoomOut = durationOut * 0.1;
                        start = state.start - zoomOut;
                        end = state.end + zoomOut;
                        break;
                }
                return {start, end};
        }
    }

    // also get the data for all calendars
    // this will only call google, if something actually changed
    useEffect(
        () => {
            // this will cache the calendar data
            function collectEvents(selectedCalendars: CalendarId[], startTime: Date, endTime: Date) {
                let { groups, itemsPromise } = collectCalendars(selectedCalendars, startTime, endTime);
                itemsPromise.then((items: ItemWrapper[]) => manageCollectedEvents(items, groups));
            }

            function manageCollectedEvents(items: ItemWrapper[], groups: Group[]) {
                if(groups.length === 0) {
                    groups = [emptyGroup];
                }

                // since we are collecting multiple timeframes in parallel, some events
                // might be collected twice (e.g. spanning over the boundaries)
                // thus we have to remove the duplicates
                const allEvents = items.filter(i => i.success)
                    .map(i =>
                        i.events.filter(event => event)
                            .map(event => ({...event, ...{bgColor: colors[i.source]}}))
                    )
                    .flat();
                let unique = new Set();
                let remove = new Set();
                for(let i=0; i<allEvents.length; ++i) {
                    const id = allEvents[i].id;
                    if(unique.has(id)) {
                        remove.add(i);
                    }
                    unique.add(id);
                }
                const uniqueEvents = allEvents
                    .filter((_val, idx) => !remove.has(idx))
                    .map((val, _idx) => val);

                const newCalendarOptions = new Set([...items.filter(i => i.success).map(i => i.calendarOptions)].flat());
                // for a calendar to be invalid all request must have failed not just one
                const successes = new Set([...items.filter(i => i.success).map(i => i.id)]);
                const newCalendarInvalid = new Set([...items.filter(i => !successes.has(i.id)).map(i => i.id)]);

                // disable invalid calendars
                const activeButInvalidCalendars = [...calendarState.active].filter(id => newCalendarInvalid.has(id));
                activeButInvalidCalendars.forEach(id => calendarDispatch({type: "deactivate", id: id}));

                const validGroups = groups.filter(g => !newCalendarInvalid.has(g.id));

                setGroups(validGroups);
                setItems(uniqueEvents);
                calendarDispatch({type: "invalidate", ids: newCalendarInvalid});
                calendarDispatch({type: "addOptions", ids: newCalendarOptions});
            }

            collectEvents([...calendarState.active], startTime, endTime);
        },
        [calendarState.active, startTime, endTime, calendarDispatch, colors]
    );

    // do only apply the filter if necessary
    const filteredItems = useMemo(() => {
        // apply the filter to the collected events, case insensitive
        const re = new RegExp(filter, "i");
        return items.filter(item =>
                item.title && re.test(item.title)
            )
    }, [filter, items]);

    return(
        <>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="persistent"
                anchor="left"
                open={drawerOpen}
            >
                <DrawerHeader>
                    <IconButton
                        data-testid={"closeDrawer"}
                        onClick={() => setDrawerOpen(false)}
                    >
                        <ChevronLeftIcon />
                    </IconButton>
                </DrawerHeader>

                <Divider />

                <Settings
                    calendarOptions={calendarState.options}
                    calendarInvalid={calendarState.invalid}
                    calendarActive={calendarState.active}
                    calendarIDs={calendarState.ids}
                    calendarGroups={calendarState.groups}

                    handleAddCalendar={(id: CalendarId) => handleAddCalendar(id)}
                    handleSelectCalendar={(id: CalendarId, isActive: boolean) => handleSelectCalendar(id, isActive)}
                    handleRemoveCalendar={(id: CalendarId) => handleRemoveCalendar(id)}
                    handleActivateCalendars={(ids: CalendarId[]) => handleActivateCalendars(ids)}
                    handleSaveCalendarGroup={(name: string, selection: string[]) => handleSaveCalendarGroup(name, selection)}
                    handleDeleteCalendarGroup={(name: string) => handleDeleteCalendarGroup(name)}

                    setFilter={(filter: string) => setFilter(filter)}
                    jumpTo={(jump: Jump) => jumpTo(jump)}
                    // TODO: I should handle the google login the same way -- probably
                    msLoginChanged={loggedIn => msLoginChanged(loggedIn)}

                    colors={colors}
                    colorsUpdated={(colors) => setColors(colors)}

                    storageChanged={() => loadStorage()}
                />
            </Drawer>

            <Main drawerOpen={drawerOpen}>
                <SimpleTimeline
                    groups={groups}
                    items={filteredItems}
                    startTime={visibleTime.start}
                    endTime={visibleTime.end}
                    timeFrameChanged={(start, end) => timeFrameChanged(start, end)}
                    visibleTimeChanged={(start, end) => visibleTimeChanged(start, end)}
                    colors={colors}
                    sidebarComponent={
                        <Button
                            data-testid={"toggleDrawer"}
                            color="inherit"
                            onClick={_event => toggleDrawerOpen()}
                            startIcon={<MenuIcon/>}
                        >
                            {t("menu")}
                        </Button>
                    }
                />
            </Main>
        </>
    );
}
