import {TextField, Tooltip} from "@mui/material";
import {useState} from "react";
import {useTranslation} from "react-i18next";

type Props = {
    updateFilter: (filterTerm: string) => void
}

export default function FilterSelect(props: Props) {
    const [regexError, setRegexError] = useState<string|null>(null);

    const { t } = useTranslation();

    function isValid(filter: string) {
        try {
            new RegExp(filter, "i");
        } catch (e) {
            if (e instanceof SyntaxError) {
                setRegexError(e.message);
            }
            return false;
        }

        setRegexError(null);
        return true;
    }

    return <>
        <Tooltip
            title={t<string>("settings.filterSelect.tooltip")}
            arrow
        >
            <TextField
                label={t("settings.filterSelect.placeholder")}
                id={"filterInput"}
                onChange={event => {isValid(event.target.value) && props.updateFilter(event.target.value)}}
                error={regexError !== null}
                helperText={regexError !== null && regexError}
            />
        </Tooltip>
    </>
}