import {MouseEvent, useState} from "react";
import {
    Autocomplete,
    IconButton, Paper,
    Stack,
    TextField,
    Typography
} from "@mui/material";

import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import CalendarSelectionElement from "./CalendarSelectionElement";
import {useTranslation} from "react-i18next";
import {CalendarId} from "../CalendarView";

type Props = {
    calendarIDs: CalendarId[],
    calendarIdOptions: Set<CalendarId>,
    filterterm: string,
    calendarActive: Set<CalendarId>,
    calendarInvalid: Set<CalendarId>,
    deleteCalendar: (id: CalendarId) => void,
    handleSelectCalendar: (id: CalendarId, isActive: boolean) => void,
    saveCalendarGroup: (id: CalendarId, selection: string[]) => void,
    handleAddCalendar: (id: CalendarId) => void,
    width?: number,
}

export default function CalendarSelect(props: Props) {
    const [newEntry, setNewEntry] = useState("");
    const [calendarGroupName, setCalendarGroupName] = useState("");

    const { t } = useTranslation();

    function buildCheckList() {
        return props.calendarIDs.map(id =>
            <Stack
                key={id}
                direction={"row"}
            >
                <IconButton
                    color="primary"
                    data-testid={"deleteButton:"+id}
                    aria-label="delete"
                    component="span"
                    onClick={(_event: MouseEvent) => props.deleteCalendar(id)}
                >
                    <DeleteIcon />
                </IconButton>
                <CalendarSelectionElement
                    id={id}
                    label={id}
                    active={props.calendarActive.has(id)}
                    disabled={props.calendarInvalid.has(id)}
                    handleSelectCalendar={(id, isActive) => props.handleSelectCalendar(id, isActive)}
                />
            </Stack>
        );
    }

    function saveCalendarGroup(calendarGroupName: string, calendarSelection: string[]) {
        props.saveCalendarGroup(calendarGroupName, calendarSelection)
        setCalendarGroupName("")
    }

    const calendarOptions = buildCheckList();

    // TODO: for now the IDs are the labels, but this should change in the future
    const calendarLabels = [...new Set([...props.calendarIDs, ...props.calendarIdOptions])]
        .filter(id => id.includes(props.filterterm))
        .sort();

    const calendarSelection = props.calendarIDs
        .filter(id => props.calendarActive.has(id));

    return(
        <Stack spacing={2}>
            <Typography variant={"h6"}>
                {t("settings.calendarSelect.add")}
            </Typography>
            <Stack direction={"row"} spacing={2}>
                <Autocomplete
                    freeSolo
                    disablePortal
                    id={"calendarAdder"}
                    options={calendarLabels}
                    sx={{ width: props.width || 250 }}
                    value={newEntry}
                    renderInput={(params) =>
                        <TextField
                            InputLabelProps={params.InputLabelProps}
                            InputProps={params.InputProps}
                            inputProps={params.inputProps}
                            disabled={params.disabled}
                            fullWidth={params.fullWidth}
                            id={"calendarIdInput"}
                            label="email"
                            onKeyDown={event => {
                                const target = event.target as HTMLTextAreaElement;
                                if (event.code === 'Enter' && target.value) {
                                    props.handleAddCalendar(target.value);
                                }
                            }}
                        />
                    }
                    onInputChange={(event, newValue) => setNewEntry(newValue)}
                    onChange={(_event, value, _reason) => value !== null && props.handleAddCalendar(value)}
                />
                <IconButton
                    id={"calendarAdderSubmit"}
                    color="primary"
                    aria-label="add"
                    component="span"
                    onClick={(_event: MouseEvent) => props.handleAddCalendar(newEntry)}
                >
                    <AddIcon />
                </IconButton>
            </Stack>

            <Typography variant={"h6"}>
                {t("settings.calendarSelect.shown")}
            </Typography>
            <Paper style={{maxHeight: 200, overflow: 'auto'}}>
                <Stack spacing={2}>
                    {calendarOptions}
                </Stack>
            </Paper>

            <Typography variant={"h6"}>
                {t("settings.calendarSelect.saveGroup")}
            </Typography>
            <Stack direction={"row"} spacing={2}>
                <TextField
                    label={t("settings.calendarSelect.save")}
                    id={"groupAdder"}
                    value={calendarGroupName}
                    onChange={event => setCalendarGroupName(event.target.value)}
                    onKeyDown={
                        event => event.key === "Enter"
                        && saveCalendarGroup(calendarGroupName, calendarSelection)
                    }
                />
                <IconButton
                    id={"calendarGroupAdderSubmit"}
                    color="primary"
                    aria-label="save"
                    component="span"
                    onClick={(_event: MouseEvent) =>
                        saveCalendarGroup(calendarGroupName, calendarSelection)
                    }
                >
                    <SaveIcon />
                </IconButton>
            </Stack>
        </Stack>
    );
}
