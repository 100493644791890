import {Stack} from "@mui/material";
import {useTranslation} from "react-i18next";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import NavigationElement from "./NavigationElement";

export type Jump =
    "zoomIn"
    |"zoomOut"
    |"dayNow"
    |"dayPrev"
    |"dayNext"
    |"dayZoom"
    |"weekNow"
    |"weekPrev"
    |"weekNext"
    |"weekZoom"
    |"fortnightNow"
    |"fortnightPrev"
    |"fortnightNext"
    |"fortnightZoom"
    |"monthNow"
    |"monthPrev"
    |"monthNext"
    |"monthZoom"
    |"quarterNow"
    |"quarterPrev"
    |"quarterNext"
    |"quarterZoom";

type Props = {
    jumpTo: (jump: Jump) => void
}

export default function NavigationSelect(props: Props) {
    const { t } = useTranslation();

    return <Stack spacing={2} alignItems="stretch">
        <NavigationElement
            jumpTo={(jump: Jump) => props.jumpTo(jump)}
            contents={[
                {key: "zoomIn", content:<ZoomInIcon/>},
                {key: "zoomOut", content:<ZoomOutIcon/>}
            ]}
        />

        <NavigationElement
            jumpTo={(jump: Jump) => props.jumpTo(jump)}
            contents={[
                {key: "dayPrev", content:<ChevronLeftIcon/>},
                {key: "dayNow", content:t("settings.navigationSelect.today")},
                {key: "dayNext", content:<ChevronRightIcon/>},
                {key: "dayZoom", content:<ZoomInIcon/>},
            ]}
        />

        <NavigationElement
            jumpTo={(jump: Jump) => props.jumpTo(jump)}
            contents={[
                {key: "weekPrev", content:<ChevronLeftIcon/>},
                {key: "weekNow", content:t("settings.navigationSelect.week")},
                {key: "weekNext", content:<ChevronRightIcon/>},
                {key: "weekZoom", content:<ZoomInIcon/>},
            ]}
        />

        <NavigationElement
            jumpTo={(jump: Jump) => props.jumpTo(jump)}
            contents={[
                {key: "fortnightPrev", content:<ChevronLeftIcon/>},
                {key: "fortnightNow", content:t("settings.navigationSelect.fortnight")},
                {key: "fortnightNext", content:<ChevronRightIcon/>},
                {key: "fortnightZoom", content:<ZoomInIcon/>},
            ]}
        />

        <NavigationElement
            jumpTo={(jump: Jump) => props.jumpTo(jump)}
            contents={[
                {key: "monthPrev", content:<ChevronLeftIcon/>},
                {key: "monthNow", content:t("settings.navigationSelect.month")},
                {key: "monthNext", content:<ChevronRightIcon/>},
                {key: "monthZoom", content:<ZoomInIcon/>},
            ]}
        />

        <NavigationElement
            jumpTo={(jump: Jump) => props.jumpTo(jump)}
            contents={[
                {key: "quarterPrev", content:<ChevronLeftIcon/>},
                {key: "quarterNow", content:t("settings.navigationSelect.quarter")},
                {key: "quarterNext", content:<ChevronRightIcon/>},
                {key: "quarterZoom", content:<ZoomInIcon/>},
            ]}
        />
    </Stack>
}