import {Checkbox, FormControlLabel} from "@mui/material";
type Props = {
    id: string,
    label: string,
    active: boolean,
    disabled: boolean
    handleSelectCalendar: (id: string, isActive: boolean) => void
}

export default function CalendarSelectionElement(props: Props) {
    return(
        <FormControlLabel
            label={props.label}
            control={
                <Checkbox
                    data-testid={props.label}
                    checked={props.active}
                    disabled={props.disabled}
                    onChange={_event => props.handleSelectCalendar(props.id, !props.active)}
                />
            }
        />
    )
}
