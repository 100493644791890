import {useAccount, useMsal} from "@azure/msal-react";
import {Button, Stack, Typography} from "@mui/material";
import {callMsGraph} from "../collectOutlookCalendar";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

type Props = {
    msLoginChanged: (loggedIn: boolean) => void
}

export default function MsAuth(props: Props) {
    const {instance, accounts, inProgress} = useMsal();

    const account = useAccount(accounts[0] || {});
    const [user, setUser] = useState<null|{userPrincipalName: string}>(null);

    const { msLoginChanged } = props;
    const { t } = useTranslation();

    useEffect(() => {
        if(account) {
            callMsGraph("me").then((result) => setUser(result))
        }
        msLoginChanged(!!account)
        // `msLoginChanged` is a dependency (but it should never really change)
        // and since it is technically changed on every call, I would generate an infinite loop
        // FIXME: do this in a proper way
        // eslint-disable-next-line
    }, [account, instance]);

    function tryLogin() {
        try {
            instance.loginPopup()
        } catch (error) {
            console.log("error during login", error);
            instance.logout();
        }
    }

    return <Stack spacing={2}>
        <Typography variant={"h6"}>
            {t("msAuth.title")}
        </Typography>
        {!(accounts.length > 0) &&
        <Button
            id="ms_authorize_button"
            variant={'contained'}
            onClick={() => tryLogin()}
        >
            {t("msAuth.login")}
        </Button>
        }

        {(accounts.length > 0) && <>
        <Button
            id="ms_logout_button"
            variant={'contained'}
            onClick={() => instance.logoutPopup()}
        >
            {t("msAuth.logout")}
        </Button>
        {inProgress !== "none" && <div>
            {t("msAuth.loggedIn")} {user && user.userPrincipalName}
        </div>}

        </>}

        {(inProgress === "login" || inProgress === "acquireToken") && <>
            <div>Login is currently in progress! Ensure that popups are enabled and maybe search for a popup below your browser window.</div>
            <div>If you are using a Work- or School-account, the admin must manually allow this app (until the app is verified.)</div>
        </>}
        {inProgress === "logout" && <>
            <div>Logging out ...</div>
            <div>Ensure that popups are enabled and maybe search for a popup below your browser window.</div>
        </>}

        {/*{errorMessage &&*/}
        {/*<pre id="errors">*/}
        {/*    {errorMessage}*/}
        {/*</pre>*/}
        {/*}*/}
    </Stack>
}
