import memoizer from "lru-memoizer";
import {GetCalendarPromiseType, ItemWrapper} from "./collectCalendars";

type CalendarEventsListResponse = {
    result: {
        items: CalendarEvent[]
    }
};

type CalendarEvent = {
    id: string,
    summary: string,
    description: string,
    htmlLink: string,
    start: {date: Date, dateTime: Date},
    end: {date: Date, dateTime: Date},
    attendees: CalendarAttendee[]
}

type CalendarAttendee = {
    email: string
}

// memoize the results of the api request for an hour
export const getCalendarPromise: GetCalendarPromiseType = memoizer.sync({
        // @ts-ignore
        load: (calendarId: string, startTime: Date, endTime: Date) => {
            return collect(
                calendarId, startTime, endTime
            ).then((responses: CalendarEventsListResponse[]) => {
                return responseToItem(calendarId, responses)
            }).catch((responses: CalendarEventsListResponse[]) => {
                return failToItem(calendarId, responses);
            });
        },

        //defines how to create a cache key from the params.
        // @ts-ignore
        hash: (calendarId, startTime, endTime) => {
            return JSON.stringify([calendarId, startTime, endTime]);
        },

        max: 1000,
        // allow refresh every hour
        maxAge: 1000 * 60 * 60
});

async function collect(calendarId: string, startTime: Date, endTime: Date): Promise<CalendarEventsListResponse[]> {
    let responses = [];
    let response = await window.gapi.client.calendar.events.list({
        'calendarId': calendarId,
        'timeMin': startTime.toISOString(),
        'timeMax': endTime.toISOString(),
        'showDeleted': false,
        'singleEvents': true,
        'maxResults': 250,
        'orderBy': 'startTime'
    });
    responses.push(response);

    let token = response.result.nextPageToken;

    while(token) {
        console.log("there is more:", token);
        response = await window.gapi.client.calendar.events.list({
            'calendarId': calendarId,
            'timeMin': startTime.toISOString(),
            'timeMax': endTime.toISOString(),
            'showDeleted': false,
            'singleEvents': true,
            'maxResults': 250,
            'orderBy': 'startTime',
            'pageToken': token
        });
        responses.push(response);

        token = response.result.nextPageToken;
    }

    return responses;
}

function responseToItem(calendarId: string, responses: CalendarEventsListResponse[]): ItemWrapper {
    const events = responses.flatMap(resp => resp.result.items);

    const eventList = events.map(event => {
        let start = event.start.dateTime;
        if (!start) {
            start = event.start.date;
        }
        let end = event.end.dateTime;
        if (!end) {
            end = event.end.date;
        }

        return {
            id: calendarId + ":" + event.id,
            group: calendarId,
            title: event.summary,
            description: event.description,
            link: event.htmlLink,
            start: (new Date(start)).getTime(),
            end: (new Date(end)).getTime(),
            source: "google",
            canMove: false,
            canChangeGroup: false,
            canResize: false
        };
    });

    const attendees = events.map(event => event.attendees).flat()
        .filter(att => att && "email" in att)
        .map(a => a.email);

    return {
        id: calendarId,
        success: true,
        events: eventList,
        calendarOptions: attendees,
        source: "google"
    }
}

function failToItem(calendarId: string, _responses: CalendarEventsListResponse[]): ItemWrapper {
    // TODO: I should probably parse the reason for the fail
    console.log("nope");
    return {
        id: calendarId,
        success: false,
        events: [],
        calendarOptions: [],
        source: "google"
    };
}
