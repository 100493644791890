import React from 'react';
import {Stack, Typography} from "@mui/material";
import ColorPopper from "./ColorPopper";
import {isSignedIn as isMsSignedIn} from "../../collectOutlookCalendar";

export type SourceColors = {
    google: string,
    outlook: string
}

export const defaultColors: SourceColors = {
    google: "#0F9D58",
    outlook: "#0072c6",
    // default: "#888888"
}

type Props = {
    colors: SourceColors
    colorsUpdated: (colors: SourceColors) => void
}

export default function ColorSelect(props: Props) {
    console.log(props.colors["google"], props.colors["outlook"])

    return <Stack spacing={2}>
        <Stack spacing={2} direction={"row"}>
            <ColorPopper
                color={props.colors["google"]}
                setColor={(color) => props.colorsUpdated({...props.colors, google: color})}
            />
            <Typography>
                Google
            </Typography>
        </Stack>

        {isMsSignedIn() && <Stack spacing={2} direction={"row"}>
            <ColorPopper
                color={props.colors["outlook"]}
                setColor={(color) => props.colorsUpdated({...props.colors, outlook: color})}
            />
            <Typography>
                Outlook
            </Typography>
        </Stack>}
    </Stack>
}
