import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";

const localStorageKeys = [
    "calendarIDs",
    "calendarActive",
    "calendarGroups",
    "settingsVisibility",
    "sourceColors"
] as const;
type LocalStorageKey = typeof localStorageKeys[number];

export function getObjFromStorage(key: LocalStorageKey, defaultValue: string) {
    let string = localStorage.getItem(key);
    if (string === undefined || string === null) {
        string = defaultValue;
    }
    return JSON.parse(string);
}

export function saveObjToStorage(key: LocalStorageKey, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
}

export function exportJson() {
    let obj: {[key: string]: any} = {};
    for(const key of localStorageKeys) {
        obj[key] = getObjFromStorage(key, "[]");
    }
    return JSON.stringify(obj);
}

export function importJson(str: string) {
    const obj = JSON.parse(str);
    for(const key of localStorageKeys) {
        if(key in obj) {
            saveObjToStorage(key, obj[key]);
        }
    }
}

type Props = {
    storageChanged: () => void
}

export default function ImportExport(props: Props) {
    const { t } = useTranslation();
    const [jsonError, setJsonError] = useState<Error|null>(null)

    function downloadJson() {
        const url = window.URL.createObjectURL(
            new Blob([exportJson()], {type : 'application/json'}),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
            'download',
            `calendarOptions.json`,
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode && link.parentNode.removeChild(link);
    }

    function onUpload(file: File) {
        file.text()
            .then((str: string) => importJson(str))
            .then(() => props.storageChanged())
            .catch(reason => setJsonError(reason));
    }

    useEffect(() => {jsonError!==null && console.log(jsonError)}, [jsonError]);

    return <Stack spacing={2}>
        <Button
            variant="outlined"
            component="label"
            data-testid={"importButton"}
        >
            {t("settings.port.imbutton")}
            <input
                type="file"
                hidden
                accept=".json"
                onChange={e => e.target.files && onUpload(e.target.files[0])}
                data-testid={"importInput"}
            />
        </Button>
        <Button
            onClick={() => downloadJson()}
            variant={"outlined"}
            data-testid={"exportButton"}
        >
            {t("settings.port.exbutton")}
        </Button>

        <Dialog open={jsonError!==null}>
            <DialogTitle id="detail-dialog-title">
                {t("settings.port.importErrorTitle")}
            </DialogTitle>
            <DialogContent>
                {t("settings.port.importErrorText")}
                <pre>
                    {jsonError && jsonError.message}
                </pre>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setJsonError(null)} autoFocus>
                    {t("dialog.close")}
                </Button>
            </DialogActions>
        </Dialog>
    </Stack>
}