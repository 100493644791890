import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton} from "@mui/material";
import DOMPurify from "dompurify";
import {useTranslation} from "react-i18next";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

type Props = {
    dialogOpen: boolean,
    handleDialogToggle: (bool: boolean, title?: string, description?: string) => void,
    title?: string,
    description?: string,
    link?: string
}

export default function DetailDialog(props: Props) {
    const { t } = useTranslation();

    const sanitizedLink = props.link && DOMPurify.sanitize(props.link);
    const sanitizedDescription = props.description && DOMPurify.sanitize(props.description)

    return (
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.handleDialogToggle(false)}
            aria-labelledby="detail-dialog-title"
            aria-describedby="detail-dialog-description"
        >
            <DialogTitle id="detail-dialog-title">
                {props.title}
                {/* nosemgrep : the link is sanitized */}
                {sanitizedLink && <a href={sanitizedLink} target="_blank" rel="noopener noreferrer">
                    <IconButton>
                        <OpenInNewIcon/>
                    </IconButton>
                </a>}
            </DialogTitle>
            <DialogContent>
                {/* nosemgrep : the description is sanitized */}
                {sanitizedDescription && <div dangerouslySetInnerHTML={{__html: sanitizedDescription}}/>}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDialogToggle(false)} autoFocus>
                    {t("dialog.close")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
