import {Mutex} from "async-mutex";
import {SCOPES} from "./components/GoogleAuth";

// @ts-ignore
const mutex = new Mutex();

// if the user logged in before some new necessary permission was introduced in the app
// we have to ask for that permission
// We should call this function, if a request to the new endpoint returns with 403
function refreshPermissions() {
    let options = new window.gapi.auth2.SigninOptionsBuilder({'scope': SCOPES.join(" ")});
    return window.gapi.auth2.getAuthInstance().currentUser.get().grant(options);
}

// global variable to keep track that we try only once to get new permissions.
let alreadyAskedForPermissions = false;

async function getFileId(name: string): Promise<null|string> {
    const request = window.gapi.client.request({
        'path': '/drive/v3/files',
        'method': 'GET',
        'params': {'spaces': 'appDataFolder'},
    });

    let files;
    try {
        const response = await request;

        files = response.result.files.filter((file: {name: string, id: string}) => file.name === name);
        console.log("search", response);
    } catch (e) {
        if(!alreadyAskedForPermissions) {
            alreadyAskedForPermissions = true;
            const ret = await refreshPermissions();
            console.log(ret);
            // and retry
            return getFileId(name);
        }
    }

    if (files === undefined) {
        throw new Error("Something went wrong while requesting the file list from drive")
    } else if (files.length === 0) {
        return null;
    } else if (files.length === 1) {
        console.log("id", files, files[0], files[0].id)
        return files[0].id;
    } else {
        throw new Error("There is more than one config file on drive");
    }
}

// https://stackoverflow.com/a/35182924
export async function saveJsonToDrive(name: string, data: string) {
    const boundary = '-------314159265358979323846';
    const delimiter = "\r\n--" + boundary + "\r\n";
    const close_delim = "\r\n--" + boundary + "--";

    const contentType = 'application/json';

    // ensure that multiple uploads are not run in parallel
    await mutex.runExclusive(async () => {
        const fileId = await getFileId(name);
        const method = fileId === null ? "POST" : "PATCH";

        let path = '/upload/drive/v3/files'
        let metadata: any = {
            name: name,
        };

        if(fileId !== null) {
            path = path + "/" + fileId;
        } else {
            metadata = {...metadata, parents: ['appDataFolder']}
        }

        const multipartRequestBody =
            delimiter +
            'Content-Type: application/json\r\n\r\n' +
            JSON.stringify(metadata) +
            delimiter +
            'Content-Type: ' + contentType + '\r\n\r\n' +
            data +
            close_delim;

        console.log(multipartRequestBody)

        const request = window.gapi.client.request({
            'path': path,
            'method': method,
            'params': {'uploadType': 'multipart'},
            'headers': {
                'Content-Type': 'multipart/related; boundary="' + boundary + '"'
            },
            'body': multipartRequestBody});

        await request;
    });
}

export async function loadJsonFromDrive(name: string) {
    const fileId = await getFileId(name);

    const request = window.gapi.client.request({
        'path': `/drive/v3/files/${fileId}`,
        'method': 'GET',
        'params': {'alt': 'media'},
    });

    const response = await request;
    console.log("load", response)
    return response;
}