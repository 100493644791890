import './App.css';
import {Suspense, useState} from "react";
import { MsalProvider } from "@azure/msal-react";
import {msalInstance} from "./msal";
import GoogleAuth from "./components/GoogleAuth";
import CalendarView from "./components/CalendarView";

type Props = {}

export default function App(_props: Props) {
    const [authed, setAuthed] = useState(false);

    return (
        <Suspense fallback="loading">
            {/* here we use a react context for the Microsoft auth */}
            <MsalProvider instance={msalInstance}>
                <GoogleAuth
                    authedCallback={(isAuthed: boolean) => setAuthed(isAuthed)}
                />

                {authed && <CalendarView/>}
            </MsalProvider>
        </Suspense>
    );
}
