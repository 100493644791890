import {Configuration, IPublicClientApplication, PopupRequest, PublicClientApplication} from "@azure/msal-browser";
import {isSignedIn} from "./collectOutlookCalendar";
import {Mutex} from "async-mutex";

// @ts-ignore
const mutex = new Mutex();

// MSAL configuration
const configuration: Configuration = {
    auth: {
        // plain text is fine, since this is not a secret
        // @ts-ignore
        clientId: process.env.REACT_APP_MS_CLIENT_ID
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
    scopes: [
        "User.Read",
        "Calendars.Read"
    ]
};

export type MsEndpoint = "me" | "calendarEvents";

function buildCalendarPath(id?: string): string {
    if(id === "primary" || id === undefined) {
        return base + "/me/calendar/events"
    } else {
        return base + "/users/" + id + "/calendar/events"
    }
}

const base = "https://graph.microsoft.com/v1.0"
// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig: {[key in MsEndpoint]: ((id?: string) => string)} = {
    me: () => base + "/me",
    calendarEvents: (id) => buildCalendarPath(id)
};

export const acquireAccessToken = async (msalInstance: IPublicClientApplication) => {
    // ensure that we try to aquire tokens sequentially, otherwise it is possible to spawn very many popups
    return await mutex.runExclusive(async () => {
        const activeAccount = msalInstance.getActiveAccount();
        const accounts = msalInstance.getAllAccounts();

        console.log("acccount", activeAccount || accounts[0])

        if (!isSignedIn()) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }
        const request = {
            ...loginRequest,
            account: activeAccount || accounts[0]
        };

        let authResult
        try {
            authResult = await msalInstance.acquireTokenSilent(request);
        } catch (error) {
            try {
                const request = {
                    ...loginRequest,
                    account: activeAccount || accounts[0]
                };
                authResult = await msalInstance.acquireTokenPopup(request);
            } catch (error) {
                console.log("error with popup", error);
                msalInstance.logout();
                return null
            }
        }

        return authResult
    });
};

export const msalInstance = new PublicClientApplication(configuration);

