import SettingEntry from "./SettingEntry";
import CalendarSelect from "./CalendarSelect";
import CalendarGroupSelect from "./CalendarGroupSelect";
import FilterSelect from "./FilterSelect";
import HelpText from "./HelpText";
import {Box, Button, Divider, Stack} from "@mui/material";
import React, {useEffect, useState} from "react";
import {CalendarId} from "../CalendarView";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {useTranslation} from "react-i18next";
import LanguageSelect from "./LanguageSelect";
import ImportExport, {getObjFromStorage, saveObjToStorage} from "./ImportExport";
import NavigationSelect, {Jump} from "./Navigation";
import MsAuth from "../MsAuth";
import ColorSelect, {SourceColors} from "./ColorSelect";

export type SettingsVisibilityId =
    "languageSelect"
    | "calendarSelect"
    | "filterSelect"
    | "helpText"
    | "calendarGroupSelect"
    | "importExport"
    | "navigationSelect"
    | "auth"
    | "sourceColor"
;

export type SettingsVisibility = {
    [id in SettingsVisibilityId]: {isVisible: boolean};
};

type Props = {
    calendarOptions: Set<CalendarId>,
    calendarInvalid: Set<CalendarId>,
    calendarActive: Set<CalendarId>,
    calendarIDs: CalendarId[],
    calendarGroups: {[key: CalendarId]: CalendarId[]},

    handleAddCalendar: (id: CalendarId) => void
    handleSelectCalendar: (id: CalendarId, isActive: boolean) => void
    handleRemoveCalendar: (id: CalendarId) => void
    handleActivateCalendars: (ids: CalendarId[]) => void
    handleSaveCalendarGroup: (name: string, selection: string[]) => void
    handleDeleteCalendarGroup: (name: string) => void

    setFilter: (filter: string) => void
    jumpTo: (jump: Jump) => void
    msLoginChanged: (loggedIn: boolean) => void

    colors: SourceColors
    colorsUpdated: (colors: SourceColors) => void

    storageChanged: () => void
}

function Settings(props: Props) {
    const { t } = useTranslation();

    const defaultVisibility: SettingsVisibility = {
        languageSelect: {isVisible: true},
        calendarSelect: {isVisible: true},
        filterSelect: {isVisible: true},
        helpText: {isVisible: true},
        calendarGroupSelect: {isVisible: true},
        importExport: {isVisible: true},
        navigationSelect: {isVisible: true},
        auth: {isVisible: true},
        sourceColor: {isVisible: true}
    }

    const visibility = loadVisibility();

    const [settingsVisibility, setSettingsVisibility] = useState<SettingsVisibility>(visibility);

    // save the setting visibility
    useEffect(() => {
        saveObjToStorage('settingsVisibility', settingsVisibility);
    }, [settingsVisibility])

    function allSettingsVisible() {
        return Object.entries(settingsVisibility).every(([_key, value]) => value.isVisible);
    }

    function toggleVisibility(key?: SettingsVisibilityId) {
        let oldState = {...settingsVisibility};

        if(key === undefined) {
            const desiredState = !allSettingsVisible();
            let k: SettingsVisibilityId;
            for (k in oldState) {
                oldState[k].isVisible = desiredState;
            }

        } else {
            oldState[key].isVisible = !oldState[key].isVisible;
        }

        setSettingsVisibility(oldState);
    }

    function loadVisibility(): SettingsVisibility {
        const loadedVisibility = getObjFromStorage('settingsVisibility', '{}');
        // fill undefined properties with the default properties
        return {...defaultVisibility, ...loadedVisibility};
    }

    function storageChanged() {
        setSettingsVisibility(loadVisibility());
        props.storageChanged();
    }

    return <Stack>
        <Box sx={{justifyContent: 'flex-end'}}>
            <Button
                key={"all"}
                color="inherit"
                onClick={_event => toggleVisibility()}
                startIcon={allSettingsVisible() ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            >
                {allSettingsVisible() ? t("settings.all.hide") : t("settings.all.show")}
            </Button>
        </Box>

        <Divider />

        <SettingEntry
            id={"navigationSelect"}
            label={t("settings.navigationSelect.label")}
            isVisible={settingsVisibility.navigationSelect.isVisible}
            toggleVisibility={() => toggleVisibility("navigationSelect")}
            component={
                <NavigationSelect
                    jumpTo={(jump) => props.jumpTo(jump)}
                />
            }
        />

        <SettingEntry
            id={"calendarSelect"}
            label={t("settings.calendarSelect.label")}
            isVisible={settingsVisibility.calendarSelect.isVisible}
            toggleVisibility={() => toggleVisibility("calendarSelect")}
            component={
                <CalendarSelect
                    calendarIDs={props.calendarIDs}
                    calendarActive={props.calendarActive}
                    calendarInvalid={props.calendarInvalid}
                    calendarIdOptions={props.calendarOptions}
                    // TODO: this should be configurable somehow
                    //  For now limit it to arbitrary emails
                    filterterm={"@"}
                    handleAddCalendar={(id: CalendarId) => props.handleAddCalendar(id)}
                    handleSelectCalendar={(id: CalendarId, isActive: boolean) => props.handleSelectCalendar(id, isActive)}
                    deleteCalendar={(id: CalendarId) => props.handleRemoveCalendar(id)}
                    saveCalendarGroup={(name: string, selection: CalendarId[]) => props.handleSaveCalendarGroup(name, selection)}
                />
            }
        />

        <SettingEntry
            id={"calendarGroupSelect"}
            label={t("settings.calendarGroupSelect.label")}
            isVisible={settingsVisibility.calendarGroupSelect.isVisible}
            toggleVisibility={() => toggleVisibility("calendarGroupSelect")}
            component={
                <CalendarGroupSelect
                    calendarGroups={props.calendarGroups}
                    activateCalendars={(selection: CalendarId[]) => props.handleActivateCalendars(selection)}
                    deleteCalendarGroup={
                        (calendarGroupName: string) => props.handleDeleteCalendarGroup(calendarGroupName)
                    }
                />
            }
        />

        <SettingEntry
            id={"filterSelect"}
            label={t("settings.filterSelect.label")}
            isVisible={settingsVisibility.filterSelect.isVisible}
            toggleVisibility={() => toggleVisibility("filterSelect")}
            component={
                <FilterSelect
                    updateFilter={(filterTerm: string) => props.setFilter(filterTerm)}
                />
            }
        />

        <SettingEntry
            id={"sourceColor"}
            label={t("settings.sourceColor.label")}
            isVisible={settingsVisibility.sourceColor.isVisible}
            toggleVisibility={() => toggleVisibility("sourceColor")}
            component={
                <ColorSelect
                    colors={props.colors}
                    colorsUpdated={(colors) => props.colorsUpdated(colors)}
                />
            }
        />

        <SettingEntry
            id={"auth"}
            label={t("settings.auth.label")}
            isVisible={settingsVisibility.auth.isVisible}
            toggleVisibility={() => toggleVisibility("auth")}
            component={
                <Stack spacing={2}>
                    <MsAuth
                        msLoginChanged={loggedIn => props.msLoginChanged(loggedIn)}
                    />
                </Stack>
            }
        />

        <SettingEntry
            id={"importExport"}
            label={t("settings.port.label")}
            isVisible={settingsVisibility.importExport.isVisible}
            toggleVisibility={() => toggleVisibility("importExport")}
            component={
                <ImportExport
                    storageChanged={() => storageChanged()}
                />
            }
        />

        <SettingEntry
            id={"languageSelect"}
            label={t("settings.languageSelect.label")}
            isVisible={settingsVisibility.languageSelect.isVisible}
            toggleVisibility={() => toggleVisibility("languageSelect")}
            component={
                <LanguageSelect/>
            }
        />

        <SettingEntry
            id={"helpText"}
            label={t("settings.helpText.label")}
            isVisible={settingsVisibility.helpText.isVisible}
            toggleVisibility={() => toggleVisibility("helpText")}
            component={
                <HelpText/>
            }
        />
    </Stack>
}

function cmpProps(prevProps: Props, nextProps: Props) {
    return (
        // these are just for autocomplete, not important
        prevProps.calendarOptions.size === nextProps.calendarOptions.size &&
        // this hurts me. The order of the arrays from the sets is not specified, but it should be the same
        // for same sets. And if they are not, it just has a slight performance penalty
        JSON.stringify([...prevProps.calendarInvalid]) === JSON.stringify([...nextProps.calendarInvalid]) &&
        JSON.stringify([...prevProps.calendarActive]) === JSON.stringify([...nextProps.calendarActive]) &&
        JSON.stringify(prevProps.calendarIDs) === JSON.stringify(nextProps.calendarIDs) &&
        JSON.stringify(prevProps.calendarGroups) === JSON.stringify(nextProps.calendarGroups)
    )
}

export default React.memo(Settings, cmpProps)