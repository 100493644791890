import {useTranslation} from "react-i18next";
import {Button, ButtonGroup} from "@mui/material";

type Props = {}

export default function LanguageSelect(props: Props) {
    const { i18n } = useTranslation();
    const supportedLangs = ["en", "de"];
    const langButtons = supportedLangs.map(lang =>
        <Button
            key={lang}
            onClick={() => i18n.changeLanguage(lang)}
            variant={lang===i18n.language ? "contained" : "outlined"}
        >
            {lang}
        </Button>
    );
    return <ButtonGroup>
        {langButtons}
    </ButtonGroup>
}
