import {Button, ButtonGroup, Tooltip} from "@mui/material";
import {Jump} from "./Navigation";
import {useTranslation} from "react-i18next";

type Props = {
    contents: {key: Jump, content: JSX.Element}[],
    jumpTo: (jump: Jump) => void
}

export default function NavigationElement(props: Props) {
    const { t } = useTranslation();

    const buttons = props.contents.map((c) => (
        <Tooltip key={c.key} title={t<string>("settings.navigationSelect." + c.key + "Tooltip")}>
            <Button
                data-testid={c.key}
                onClick={() => props.jumpTo(c.key)}
                sx={{overflow: "hidden"}}
            >
                {c.content}
            </Button>
        </Tooltip>
    ))

    return <ButtonGroup>
        {buttons}
    </ButtonGroup>
}