
// https://codesandbox.io/s/w6xvqzno4w?file=/generate-fake-data.js
import {Group, Item} from "./components/SimpleTimeline";
import moment from "moment";
import {getCalendarPromise as getGoogleCalendarPromise} from "./collectGoogleCalendar";
import {getCalendarPromise as getOutlookCalendarPromise, isSignedIn as isMsSignedIn} from "./collectOutlookCalendar";

export type ItemWrapper = {
    id: string,
    success: Boolean,
    events: Item[],
    calendarOptions: string[],
    source: "google"|"outlook"
}

export type GetCalendarPromiseType = (calendarId: string, startTime: Date, endTime: Date) => Promise<ItemWrapper>;

export function collectCalendars(calendarIDs: string[], start: Date, end: Date): { groups: Group[], itemsPromise: Promise<ItemWrapper[]> } {
    const groups = calendarIDs.map(mail => ({
        id: mail,
        title: mail,
        rightTitle: mail
    }))

    // split the time between `start` and `end` into whole weeks and iterate over the weeks
    // this way we can change start and end and still get good usage of the cache
    const startMonthNormalized = moment(start)
        .startOf("week")
        .toDate();
    const endMonthNormalized = moment(end)
        .endOf("week")
        .toDate();

    let current = new Date(startMonthNormalized);
    let itemWeekPromises = [];

    do {
        const begin = new Date(current);
        const next = moment(current)
            .add(1, "week")
            .toDate();
        itemWeekPromises.push(calendarIDs.map(id => getGoogleCalendarPromise(id, begin, next)))
        isMsSignedIn() && itemWeekPromises.push(calendarIDs.map(id => getOutlookCalendarPromise(id, begin, next)))
        current = next;
    } while(current < endMonthNormalized);

    const itemPromises = itemWeekPromises.flat(1);
    const itemsPromise = Promise.all(itemPromises);

    return { groups, itemsPromise }
}