import {Box, Button, Collapse, Paper} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {SettingsVisibilityId} from "./Settings";

type Props = {
    id: SettingsVisibilityId
    isVisible: boolean,
    label: string,
    toggleVisibility: (key: SettingsVisibilityId) => void,
    component: JSX.Element
}

export default function SettingEntry(props: Props) {
    return (
        <Box
            data-testid={props.id}
            sx={{justifyContent: 'flex-end'}}
        >
            <Button
                key={props.id}
                color="inherit"
                onClick={_event => props.toggleVisibility(props.id)}
                startIcon={props.isVisible ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
            >
                {props.label}
            </Button>
            <Collapse in={props.isVisible}>
                <Box component={Paper} m={2} p={2}>
                    {props.component}
                </Box>
            </Collapse>
        </Box>
    )
}