import {MouseEvent, useEffect, useState} from "react";
import {Button, Typography, Grid} from "@mui/material";
import {useTranslation} from "react-i18next";

// https://developers.google.com/calendar/api/quickstart/js

// Client ID from the Developer Console
// this one is public, so we do not keep to worry about keeping it secret
// https://console.cloud.google.com/apis/credentials?project=calendaroverview
const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
// Also, we do not need an API key

// Array of API discovery doc URLs for APIs used by the quickstart
const DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"];

// Authorization scopes required by the API; multiple scopes can be
// included, separated by spaces.
export const SCOPES = [
    "https://www.googleapis.com/auth/calendar.readonly",
    "https://www.googleapis.com/auth/drive.appdata"
];

const SOURCE = "https://apis.google.com/js/api.js";

type Props = {
    authedCallback: (b: boolean) => void,
    source?: string,
    clientId?: string
}

type GapiType = any;
type GapiErrorType = any;

// https://stackoverflow.com/a/56458070
declare global {
    interface Window {
        gapi: GapiType;
    }
}

export default function GoogleAuth(props: Props) {
    const [isSignedIn, setIsSignedIn] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [user, setUser] = useState<string|null>(null)

    const { t } = useTranslation();

    const { source = SOURCE, clientId = CLIENT_ID, authedCallback } = props;

    useEffect(
        () => {
            // On load, called to load the auth2 library and API client library.
            function handleClientLoad() {
                window.gapi.load('client:auth2', () => initClient());
            }

            // Initializes the API client library and sets up sign-in state listeners.
            function initClient() {
                window.gapi.client.init({
                    clientId: clientId,
                    discoveryDocs: DISCOVERY_DOCS,
                    scope: SCOPES.join(" ")
                }).then(() => {
                    // Listen for sign-in state changes.
                    window.gapi.auth2.getAuthInstance().isSignedIn.listen((status: boolean) => updateSigninStatus(status));
                    window.gapi.auth2.getAuthInstance().currentUser.listen(
                        (currentUser: any) => setUser(currentUser.getBasicProfile().getEmail())
                    );

                    // Handle the initial sign-in state.
                    updateSigninStatus(window.gapi.auth2.getAuthInstance().isSignedIn.get());
                    if(window.gapi.auth2.getAuthInstance().isSignedIn.get()) {
                        setUser(window.gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile().getEmail());
                    }
                }, (error: GapiErrorType) => {
                    setErrorMessage(JSON.stringify(error, null, 2));
                });
            }

            // Called when the signed in status changes, to update the UI
            // appropriately. After a sign-in, the API is called.
            function updateSigninStatus(isSignedIn: boolean) {
                setIsSignedIn(isSignedIn);
                authedCallback(isSignedIn);
            }

            // append the script used in https://developers.google.com/calendar/api/quickstart/js
            // to the html, and start the OAuth process as soon as its loaded
            const script = document.createElement("script");
            script.async = true;
            script.defer = true;
            script.src = source;

            document.body.appendChild(script);

            const callback = () => {
                if (window.gapi) {
                    handleClientLoad();
                }
            };

            script.addEventListener("load", callback);

            return () => {
                script.removeEventListener("load", callback);
                document.body.removeChild(script);
            }
        },
        [source, clientId, authedCallback]
    )

    // Sign in the user upon button click.
    function handleAuthClick(_event: MouseEvent) {
        window.gapi.auth2.getAuthInstance().signIn();
    }

    // Sign out the user upon button click.
    // function handleSignoutClick(_event: MouseEvent) {
    //     window.gapi.auth2.getAuthInstance().signOut();
    // }

    return <>{!isSignedIn &&
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{minHeight: '100vh'}}
        >
            <Grid item xs={3}>
                <Typography variant={"h6"}>
                    {t("googleAuth.title")}
                </Typography>
                {!isSignedIn &&
                <Button
                    id="authorize_button"
                    variant={'contained'}
                    onClick={handleAuthClick}
                >
                    {t("googleAuth.login")}
                </Button>
                }

                {isSignedIn &&
                <span>
                        {t("googleAuth.loggedIn")} {user}
                    </span>
                }

                {errorMessage &&
                <pre id="errors">
                        {errorMessage}
                    </pre>
                }
            </Grid>
        </Grid>
    }</>
}
