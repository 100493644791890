import React, {useState} from "react";

import "react-calendar-timeline/lib/Timeline.css";
// we need the resize detector, since our drawer will resize the calendar
// @ts-ignore
import containerResizeDetector from 'react-calendar-timeline/lib/resize-detector/container'

import Timeline, {
    TimelineHeaders,
    SidebarHeader,
    DateHeader
// @ts-ignore
} from "react-calendar-timeline/lib";
import {
    Box,
    Tooltip
} from "@mui/material";
import DetailDialog from "./DetailDialog";
import {SourceColors} from "./settings/ColorSelect";
const keys = {
    groupIdKey: "id",
    groupTitleKey: "title",
    groupRightTitleKey: "rightTitle",
    itemIdKey: "id",
    itemTitleKey: "title",
    itemDivTitleKey: "title",
    itemGroupKey: "group",
    itemTimeStartKey: "start",
    itemTimeEndKey: "end",
    groupLabelKey: "title"
};

export type Group = {
    id: string,
    title: string
    rightTitle: string,
}

export type Item = {
    id: string,
    group: string,
    title: string,
    description: string,
    start: number,  // unix timestamp
    end: number,  // unix timestamp
    canMove: boolean,
    canResize: boolean,
}

type Props = {
    groups: Group[],
    items: Item[],
    startTime: number,
    endTime: number,
    sidebarComponent: JSX.Element,
    timeFrameChanged: (start: number, end: number) => void,
    visibleTimeChanged: (start: number, end: number) => void,
    colors: SourceColors
}

export default function SimpleTimeline(props: Props) {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [title, setTitle] = useState<string|undefined>(undefined);
    const [description, setDescription] = useState<string|undefined>(undefined);
    const [link, setLink] = useState<string|undefined>(undefined);

    function handleDialogToggle(bool: boolean, title?: string, description?: string, link?: string) {
        setDialogOpen(bool);
        setTitle(title);
        setDescription(description);
        setLink(link);
    }

    // https://codesandbox.io/s/k0wn41y0o7?file=/CustomTimeline.jsx:3514-3546
    function itemRenderer({ item, getItemProps }: any) {
        // get rid of the title, since we will be using MUI tooltips
        const {title, ...props} = getItemProps({style: {borderWidth: 0, backgroundColor: item.bgColor, paddingLeft: 2}});

        return (
            <div
                style={props.style}
                onClick={() => handleDialogToggle(true, item.title, item.description, item.link)}
            >
                <Tooltip
                    title={item.title}
                    arrow
                >
                    <div
                        style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                        }}
                    >
                        {item.title}
                    </div>
                </Tooltip>
            </div>
        );
    }

    return (
        <>
        <DetailDialog
            dialogOpen={dialogOpen}
            title={title}
            description={description}
            link={link}
            handleDialogToggle={
                (bool: boolean, title?: string, description?: string, link?: string) =>
                    handleDialogToggle(bool, title, description, link)
            }
        />
        {/* this div avoids the calendar part to get  width of 0, which leads to a react error */}
        <div style={{ overflow: 'auto', minWidth: '300px' }}>
            <Timeline
                groups={props.groups}
                items={props.items}
                keys={keys}
                sidebarContent={<div>test</div>}
                itemsSorted
                itemTouchSendsClick={false}
                stackItems
                itemHeightRatio={0.75}
                showCursorLine
                canMove={false}
                canChangeGroup={false}
                canResize={false}
                visibleTimeStart={props.startTime}
                visibleTimeEnd={props.endTime}
                onTimeChange={(start: number, end: number) => props.visibleTimeChanged(start, end)}
                minZoom={3 * 60 * 60 * 1000} // 4 hours
                maxZoom={6 * 30 * 24 * 60 * 60 * 1000} // 6 months
                itemRenderer={(args: any) => itemRenderer(args)}
                onBoundsChange={(canvasTimeStart: number, canvasTimeEnd: number) => props.timeFrameChanged(canvasTimeStart, canvasTimeEnd)}
                resizeDetector={containerResizeDetector}
            >
                <TimelineHeaders className="sticky">
                    <SidebarHeader>
                        {({ getRootProps }: { getRootProps: () => any }) => {
                            return <Box
                                style={getRootProps().style}
                                sx={{margin: "auto"}}
                            >
                                {props.sidebarComponent}
                            </Box>
                        }}
                    </SidebarHeader>
                    <DateHeader unit="primaryHeader" />
                    <DateHeader />
                </TimelineHeaders>
            </Timeline>
        </div>
        </>
    );
}
